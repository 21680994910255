import axios from 'axios';

const baseURL = process.env.VUE_APP_REPPR_URL || 'http://localhost:3000';

const repprApi = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  async getMovements() {
    const { data } = await repprApi.get('/movements');
    return data;
  },
  async getRoutines() {
    const { data } = await repprApi.get('/routines');
    return data;
  },
  async getRoutineById(id) {
    const { data } = await repprApi.get(`/routines/${id}`);
    return data;
  },
  async login(email, password) {
    const { data } = await repprApi.post('/login', { email, password });
    return data;
  },
  async getSessions() {
    const { data } = await repprApi.get('/sessions');
    return data;
  },
  async createMovement(movement) {
    const { data } = await repprApi.post('/movements', movement);
    return data;
  },
  async getMovementById(id) {
    const { data } = await repprApi.get(`/movements/${id}`);
    return data;
  },
  async getRoutineMovements(id) {
    const { data } = await repprApi.get(`/routines/${id}/movements`);
    return data;
  },
  async createRoutineMovement(routineId, movement) {
    const { data } = await repprApi.post(
      `/routines/${routineId}/movements`,
      movement
    );
    return data;
  },
  async createRoutine(routine) {
    const { data } = await repprApi.post('/routines', routine);
    return data;
  },
  async deleteRoutineMovement(id) {
    const { data } = repprApi.delete(`/routine-movement/${id}`);
    return data;
  },
  setToken(token) {
    return (repprApi.defaults.headers.common.Authorization = `Bearer ${token}`);
  },
};
